
import './list.less';
import {
    defineComponent, ref, watchEffect, watch, computed, Ref,
    nextTick, reactive, inject, toRefs
} from 'vue';
import deleteDefaultFunc from '@/methods/remove-func';
import HttpRequest from '@/util/axios.config';
import {
    ListContent,
    ListActionItem
} from '../util/all-interface';
import {
    isValidAction,
    filterEmptyCellValue,
    actionEventDeal,
    getSlotName
} from '../util/util';
import {
    setListData,
    actionClass,
    getDefaultAction,
    defaultActionType
} from '../util/list-data';
import listBaseProps from '../util/list-base-prop';
import ChooseColumn from '../components/choose-column.vue';
import { setCurrentSearchParam } from '../util/search';

export default defineComponent({
    components: {
        ChooseColumn
    },
    props: listBaseProps,
    setup(prop, { emit }) {
        const tableData: Ref< Array<ListContent> > = ref([]);
        const requestDetail: Ref< Array<ListContent> > = ref([]);
        const totalCount = ref(0);
        const currentPageCount = ref(0);
        const update = ref(0);
        const actionWidth = ref(0);
        const acionRef = ref<Element | null>(null);
        const chooseColumn = ref();

        // isOpenVirtual：是否开启虚拟类别功能
        const isOpenVirtual = inject('isOpenVirtual', false);
        // 表格行高度
        const tableIRowHeight = 60;
        // 滚动的位置信息
        const positionInfo = reactive({
            scrollTop: 0,
            startIndex: 0,
            endIndex: 10
        });

        const setActionWidth = () => {
            nextTick(() => {
                if (acionRef.value) {
                    actionWidth.value = acionRef.value.getBoundingClientRect().width;
                }

                if (isOpenVirtual) {
                    // 获取虚拟列表容器
                    const listTableVirtualWrap = document.getElementsByClassName('list-table-virtual-wrap')[0];
                    const listTableMain = document.getElementsByClassName('list-table-main')[0];

                    // 设置容器样式
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    listTableVirtualWrap.style.height = `${60 * 6}px`;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    listTableMain.style.height = `${60 * (tableData.value.length + 1)}px`;
                }
            });
        };

        const actionEvent = actionEventDeal(emit);
        const actionList: Ref< Array<ListActionItem> > = ref([]);
        watch(computed(() => prop.action), () => {
            const { action } = prop;
            actionList.value = isValidAction(action) ? action.concat(getDefaultAction(prop.keyOfDelete)) : [];
        }, {
            immediate: true
        });

        const deleteActionEvent = ref(actionEventDeal(emit, prop.deleteUrl, prop.deleteMessage, update));

        // 监听deleteUrl,deleteMessage的变化
        watch(computed(() => (prop.deleteUrl + prop.deleteMessage)), () => {
            deleteActionEvent.value = actionEventDeal(emit, prop.deleteUrl, prop.deleteMessage, update);
        });

        // 组件外部触发更新
        watch(computed(() => prop.update), () => {
            update.value += 1;
        });

        // 列表数据初始化和更新
        setListData(prop.requestData, prop.listData, tableData, requestDetail, totalCount, currentPageCount, prop.dealList, setActionWidth);
        watch(update, () => {
            if (prop.saveParamInPath) setCurrentSearchParam(prop.requestData ? prop.requestData.param : {});
            setListData(prop.requestData, prop.listData, tableData, requestDetail, totalCount, currentPageCount, prop.dealList, setActionWidth);
        });

        // 组件外部获取总条数
        watch(totalCount, () => {
            emit('getTotal', totalCount.value, currentPageCount.value);
        });

        // 初始化选项,监听选项变化
        const virtualCheck = ref(prop.selectedItem);

        const initCheck = ref(prop.selectedItem);
        let selectItems: Array<ListContent> = [];
        watchEffect(() => {
            selectItems = [];
            tableData.value.forEach((tableItem) => {
                if (initCheck.value.includes(tableItem[prop.keyOfCheck])) {
                    selectItems.push(tableItem);
                }
            });
            emit('multipleSelect', initCheck.value, selectItems);
        });

        watch(computed(() => prop.selectedItem), () => {
            initCheck.value = prop.selectedItem;
        });

        // TODO 列表数据更新是，重置勾选项 --- 会不会影响到其他逻辑？？？
        watch(update, () => {
            if (prop.isClearCheck) {
                initCheck.value = [];
            }
            if (isOpenVirtual) {
                // 获取虚拟列表容器
                const listTableVirtualWrap = document.getElementsByClassName('list-table-virtual-wrap')[0];
                const listTableMain = document.getElementsByClassName('list-table-main')[0];

                // 设置容器样式
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                listTableVirtualWrap.style.height = `${60 * 6}px`;
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                listTableMain.style.height = `${60 * (tableData.value.length + 1)}px`;
                nextTick(() => {
                    listTableMain.scrollTop = 0;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    listTableMain.style.paddingTop = 0;
                });
            }
        });

        // 批量删除
        const multipleDelete = () => {
            if (initCheck.value.length !== 0) {
                const deleteUrl = prop.deleteMultipleUrl !== '' ? prop.deleteMultipleUrl : prop.deleteUrl;
                const ids = prop.deleteMultipleKeyType === 'string' ? initCheck.value.join(';') : initCheck.value;
                deleteDefaultFunc(typeof prop.deleteMultipleMessage === 'string'
                    ? prop.deleteMultipleMessage : prop.deleteMultipleMessage(selectItems), () => {
                    const formData: {
                        [key in string]: any
                    } = {};
                    console.log(prop);
                    console.log(ids);
                    formData[prop.keyOfCheck] = ids;
                    HttpRequest.post(deleteUrl,
                        formData,
                        () => {
                            update.value += 1;
                            emit('deleteMultipleCallback');
                        });
                });
            }
        };
            // 可视区域展示的列表
        const virtualList = computed(() => tableData.value.slice(positionInfo.startIndex, positionInfo.endIndex + 2));
        function scroll(e: any) {
            const { scrollTop } = e.target;
            positionInfo.scrollTop = e.target.scrollTop;
            positionInfo.startIndex = Math.floor(scrollTop / tableIRowHeight);
            positionInfo.endIndex = positionInfo.startIndex + 10;

            // eslint-disable-next-line no-unused-expressions
            positionInfo.startIndex > 2 ? positionInfo.startIndex - 2 : positionInfo.startIndex;
        }

        return {
            isOpenVirtual,
            scroll,
            virtualList,
            ...toRefs(positionInfo),

            filterEmptyCellValue,
            actionEvent,
            deleteActionEvent,
            update,
            tableData,
            requestDetail,
            actionList,
            totalCount,
            initCheck,
            actionClass,
            defaultActionType,
            getSlotName,
            multipleDelete,
            actionWidth,
            acionRef,
            chooseColumn,
            virtualCheck
        };
    }
});
