import {
    ListActionItem, ActionEventFn, RequestData,
    ListRequestResult, ListRequestResultNoRow,
    ListContent, ListDataType
} from './all-interface';

export default null;
export function filterEmptyCellValue(value: string | null | undefined | number) {
    return (value === null || value === undefined || value === '') ? '--' : value;
}

function isStringEvent(event: ActionEventFn | string): event is string {
    return typeof event === 'string';
}

export function isNotUseRequestData(requestData: RequestData | false): requestData is false {
    return requestData === false;
}

export function isListRequestResultNoRow(listRequestResult: ListRequestResult | ListRequestResultNoRow): listRequestResult is ListRequestResultNoRow {
    return 'length' in listRequestResult.data && !('total' in listRequestResult);
}

export function isArrayRequestResult(listDataResult: Array<ListContent> | ListDataType | {
    list: Array<ListContent>;
}): listDataResult is Array<ListContent> {
    return 'length' in listDataResult;
}

export function isValidAction(action: Array<ListActionItem> | false): action is Array<ListActionItem> {
    return action !== false;
}

/**
 * @name 统一处理action事件，支持外部传入方法或是直接触发事件
 * @param emit 处理外部传入action触发方法
 * @returns 处理点击action的方法
 */
export function actionEventDeal(emit: Function, ...argus: Array<any>) {
    return (actionItem: ListActionItem, value: any) => {
        if (isStringEvent(actionItem.event)) {
            emit(actionItem.event, value);
            // list外层封装一个list组件，需要持续emit
            emit('actionEmit', actionItem.event, value);
        } else {
            actionItem.event(value, ...argus);
        }
    };
}

/**
 * @name 补充slot名称
 * @param name 列名，不是label文本
 * @returns 补充完成的slot名
 */
export function getSlotName(name: string) {
    return `${name}Slot`.replace(new RegExp(' ', 'g'), '');
}
